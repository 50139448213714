import React from "react"
import { Box, Typography } from "@material-ui/core"
import theme from "../gatsby-theme-material-ui-top-layout/theme"

export default function AccentFrame(props) {
  return (
    <Box minHeight="10vh"
         border={`2px solid ${theme.palette.primary.main}`}
         borderRadius="10px"
         padding="20px"
         margin="10px">
      {props.children}
    </Box>
  )
}
