import React from "react"
import Typography from "@material-ui/core/Typography"

export default function Li(props) {
    return (
        <React.Fragment>
            <li>
                <Typography component="span">
                    {props.children}
                </Typography>
            </li>
        </React.Fragment>
    )
}
