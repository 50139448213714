import React from "react"
import {useTranslation } from "gatsby-plugin-react-i18next"
import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    sectionTitle: {
      paddingBottom: '10px',
      color: theme.palette.secondary.main,
      fontSize: '1.5em',
      marginTop: theme.spacing(3)
    },
    titleSeparator: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      borderTop: `2px solid ${theme.palette.secondary.main}`,
      width: '15%',
    },
  }))

export function SectionTitle(props) {
    const {t} = useTranslation();
    const classes = useStyles();
  
    return (
        <Box position="relative" zIndex="-1" marginBottom="10px">
          <Typography variant="h3" component="h3" className={classes.sectionTitle}>
              {props.children}
          </Typography>
          <span className={classes.titleSeparator}></span>
        </Box>
    );
  }