import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import AccentFrame from "../components/accentFrame";
import { Typography } from "@material-ui/core";
import Layout from "../components/layout";
import Li from "../components/li"
import { SectionTitle } from "../components/sectionTitle";
import { graphql } from 'gatsby';
import { useLocation } from "@reach/router";
import {appInsights} from "../appInsights";

export default function App() {
  const {t} = useTranslation();

  const location = useLocation();

  // Parse URL parameters
  const searchParams = new URLSearchParams(location.search);
  const utmSource = searchParams.get('utm_source');

  if (utmSource) {
    console.log('UserOrigin event:', { name: 'UserOrigin', properties: { origin: utmSource } });
    appInsights.trackEvent('UserOrigin', { origin: utmSource });
  }
  
  return (
      <Layout pageTitle={t("Prenatal screening test")} next="/trisomies" previous={false}>
        <Typography style={{marginTop: "15px"}}>
          {t("Home0")}
        </Typography>
        <SectionTitle>
          {t("Home1")}
        </SectionTitle>
        <Typography paragraph>
          {t("Home2")}
        </Typography>
        <Typography paragraph>
          {t("Home3")}
        </Typography>
        <Typography paragraph>
          {t("Home4")}
        </Typography>
        <Typography paragraph>
          <strong>
          {t("Home5")}
          </strong>
        </Typography>
        <SectionTitle>
          {t("Making a decision")}
        </SectionTitle>
        <Typography variant="body1">
          {t("Whether or not you decide to have the prenatal test is your own choice.")}
          <br/><br/>
          <Typography paragraph>
          {t("Your decision will not affect the quality of care and services to which you are entitled.")}
          </Typography>
          {t("To help you make the decision that is right for you, we suggest that you:")}
        </Typography>
          <ul>
            <Li>{t("Base your choice on your values and preferences.")}</Li>
            <Li>{t("Take current scientific data into account.")}</Li>
            <Li>{t("Discuss with your partner and the health professionals who support you.")}</Li>
          </ul>
        <Typography paragraph>
          <strong>{t("Home6")}</strong>
              <br/>
              {t("Home7")}
            <br/>
          <ol>
            <Li>{t("Home8")}</Li>
            <Li>{t("Home9")}</Li>
            <Li>{t("Home10")}</Li>
            <Li>{t("Home11")}</Li>
          </ol>
        </Typography>
        <AccentFrame>
          <Typography variant="body1">
            <strong>{t("A word of caution")}</strong>
            <br/>
            {t("This decision-making tool does not replace a discussion with the health professionals who support you.")}
            <br/>
            <br/>
          </Typography>

        </AccentFrame>
      </Layout>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
